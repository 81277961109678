@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: $color-off-white;
    padding: 50px 20px;
    gap: 40px;

    @include media-tablet {
        gap: 20px;
    }

    @include media-mobile {
        padding: 25px 16px 50px;
    }

    &--skeleton {
        min-height: 650px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @include media-desktop-md {
        gap: 40px;
    }

    @include media-tablet-portrait {
        gap: 20px;
    }
}

.title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
    text-align: center;

    b,
    em,
    strong {
        font-weight: 500;
        color: $color-primary;
    }

    @include media-desktop-md {
        font-size: 60px;
    }

    @include media-tablet {
        font-size: 44px;
        max-width: 20ch;
    }

    @include media-mobile {
        font-size: 36px;
    }

    @include media-mobile-xs {
        font-size: 24px;
    }

    &--skeleton {
        height: calc(72px * 1.1);
        width: 1150px !important;

        @include media-desktop-md {
            height: calc(60px * 1.1);
            width: 960px !important;
        }

        @include media-tablet {
            height: calc(44px * 1.1 * 2);
            width: 540px !important;
        }

        @include media-mobile {
            height: calc(36px * 1.1 * 2);
            width: 320px !important;
        }

        @include media-mobile-xs {
            height: calc(24px * 1.1 * 2);
            width: 150px !important;
        }
    }
}

.description {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.6;
    margin: 0;
    width: 640px;
    text-align: center;

    @include media-desktop-md {
        width: 500px;
        font-size: 16px;
    }

    @include media-mobile {
        width: unset;
        right: 0;
        left: 0;
    }

    @include media-mobile-xs {
        font-size: 12px;
    }

    &--skeleton {
        height: calc(20px * 1.1 * 2);
        width: 640px !important;

        @include media-desktop-md {
            height: calc(16px * 1.1 * 2);
            width: 500px !important;
        }

        @include media-mobile {
            height: calc(16px * 1.1 * 3);
            width: 300px !important;
        }

        @include media-mobile-xs {
            height: calc(12px * 1.1 * 2);
            width: 200px !important;
        }
    }
}

.achievements {
    justify-content: center;
    position: relative;
    display: flex !important;
    z-index: 1;
    gap: 24px !important;

    @include media-mobile {
        display: grid !important;
        position: relative;
        gap: 12px 24px !important;
    }

    &--skeleton {
        @include media-tablet-up {
            grid-template-columns: repeat(4, 150px) !important;
            display: grid !important;
        }
    }
}

.button__container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;

    > span {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 250px;

        > a {
            width: 100%;
        }
    }

    @include media-mobile {
        flex-direction: column;
        align-items: center;
        gap: 2px;
        width: 95%;

        > span {
            width: 100%;
            max-width: 250px;
        }
    }
}
